import React from "react";
import { IonRow, IonCol, IonLabel } from "@ionic/react";
import './Leaderboard.css'

interface LeaderboardHeaderProps {
  nameLabel: string;
  scoreLabel: string;
}

const LeaderboardHeader: React.FC<LeaderboardHeaderProps> = ({ nameLabel, scoreLabel }) => {
  return (
    <IonRow className="ion-text-center pt-4 pb-2">
      <IonCol>
        <IonLabel className="font-bold text-lg">
          {nameLabel}
        </IonLabel>
      </IonCol>
      <IonCol>
        <IonLabel className="font-bold text-lg">
          {scoreLabel}
        </IonLabel>
      </IonCol>
    </IonRow>
  );
};

export default LeaderboardHeader;