import React from "react";
import "./UserPage.css";


const UserHeader: React.FC = () => {
  return (
    <h2 className="text-3xl text-black font-bold sm:text-4xl">User Profile</h2>
  );
};

export default UserHeader;
