import React from "react";
import { IonIcon } from "@ionic/react";
import { person, statsChart, gameController } from "ionicons/icons";
import "./main.css";


const Navbar: React.FC = () => {
  return (
    <div className="min-w-full flex items-end justify-end fixed bottom-0 pt-5">
    <div className="w-full max-w-md mx-auto">
    <div className=" bg-gray-50 shadow-lg rounded-2xl">
            <div className="flex">
                <div className="flex-1 group">
                    <a href="/label" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-teal-500">
                        <span className="block px-1 pt-1 pb-1">
                            <IonIcon icon={gameController} />
                            <span className="block text-m pb-2 hover:text-teal-700">Label</span>
                            <span className="block w-5 mx-auto h-1 group-hover:bg-teal-500 rounded-full"></span>
                        </span>
                    </a>
                </div>
                <div className="flex-1 group">
                    <a href="/leaderboard" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-teal-500">
                        <span className="block px-1 pt-1 pb-1">
                            <IonIcon icon={statsChart} />
                            <span className="block text-m pb-2 hover:text-teal-700">Leaderboards</span>
                            <span className="block w-5 mx-auto h-1 group-hover:bg-teal-500 rounded-full"></span>
                        </span>
                    </a>
                </div>
                <div className="flex-1 group">
                    <a href="/user" className="flex items-end justify-center text-center mx-auto px-4 pt-2 w-full text-gray-400 group-hover:text-teal-500">
                        <span className="block px-1 pt-1 pb-1 ">
                            <IonIcon icon={person} />
                            <span className="block text-m pb-2 hover:text-teal-700">User</span>
                            <span className="block w-5 mx-auto h-1 group-hover:bg-teal-500 rounded-full"></span>
                        </span>
                    </a>
                </div>
                
            </div>
        </div>
    </div>
    </div>
  );
};

export default Navbar;
