import {
  IonInput,
} from "@ionic/react";

import Icon from '../icon/valyu_png/5.png';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import  "./pages.css";

import { FormEvent } from "react";
import { useFormFields } from "../lib/hooksLib";
import { Auth } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    username: "",
  });

  function validateForm() {
    return fields.username.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      await Auth.signIn(fields.username, fields.password);
      userHasAuthenticated(true);
    } catch (e: any) {
      onError(e);
    }
  }

  return (
    <>

      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={Icon}
              alt="Valyu"
            />
            
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Welcome to ValyuLabel
            </h2>
            <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            
          </div>
          <form onSubmit={handleSubmit} className="mt-8 space-y-6" >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <IonInput
                  name="email"
                  type="text"
                  id="username"
                  value={fields.username}
                  required={true}
                  onIonChange={handleFieldChange}
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="  Email address"

                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <IonInput
                  id="password"
                  name="password"
                  type="password"
                  required={true}
                  value={fields.password}
                  onIonChange={handleFieldChange}
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm pl-1" 
                  placeholder="  Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-teal-400 focus:ring-teal-600"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <span className="font-medium text-teal-400 hover:text-teal-600">
                  Forgot your password?
                </span>
              </div>
            </div>

            <div>

              <button
                type="submit"
                disabled={!validateForm()}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-teal-600 py-2 px-4 text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                </span>
                Sign in
                </button>

              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md mt-1.5 border border-transparent bg-teal-400 py-2 px-4 text-sm font-medium text-white hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                </span>
                <Link to="/signup">Sign up</Link>      
              </button>
            </div>

          </form>
        </div>
      </div>
    </>
  )
}