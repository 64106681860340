import { FormEvent, useState } from "react";
import { useHistory } from "react-router";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import { onError } from "../lib/errorLib";
import config from "../config";
import { Link } from "react-router-dom";
import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import axios from "axios";
import  "./pages.css";
import Icon from '../icon/favicon.png';

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const { userHasAuthenticated } = useAppContext();
  const nav = useHistory();
  const [newUser, setNewUser] = useState<ISignUpResult | null>(null);

  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      const newUser = await Auth.signUp({
        username: fields.username,
        password: fields.password,
        attributes: {
          email: fields.email,
        },
      });
      setNewUser(newUser);
    } catch (e) {
      onError(e);
    }
  }

  async function userInitiationHandler() {
    const session = await Auth.currentSession();
    try {
      const res = await axios.post(
        `${config.apiGateway.URL!}/initiation`,
        {},
        {
          headers: {
            Authorization: `${session.getIdToken().getJwtToken()}`,
          },
        }
      );
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleConfirmationSubmit(event: FormEvent) {
    event.preventDefault();

    try {
      await Auth.confirmSignUp(fields.username, fields.confirmationCode);
      await Auth.signIn(fields.username, fields.password);
      userHasAuthenticated(true);
      nav.push("/");
      // user initiation
      await userInitiationHandler();
    } catch (e) {
      onError(e);
    }
  }

  

  function renderConfirmationForm() {
    return (
      <IonPage className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <IonContent className="w-full max-w-md space-y-12">
          <form onSubmit={handleConfirmationSubmit}>
            <div>
              <IonItem>
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Enter your confirmation code
              </h2>
                <IonInput
                  id="confirmationCode"
                  autofocus
                  type="tel"
                  onIonChange={handleFieldChange}
                  value={fields.confirmationCode}
                  className="relative block w-full appearance-none mt-2 rounded-md border border-gray-00 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10  sm:text-sm"
                  placeholder="  Confirmation Code"
                ></IonInput>
              </IonItem>
              <span>Please check your email for the code.</span>
            </div>
            
            <div>
                <button
                  type="submit"
                  disabled={!validateConfirmationForm()}
                  className="group relative flex w-1/4 justify-center rounded-md mt-1.5 border border-transparent bg-teal-400 py-2 px-4 text-sm font-medium text-white hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  </span>
                    Verify
                </button>
              </div>

          </form>
          <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
            </svg>
            <Link to="/login" className="pl-4">Back to Login</Link>
            </div>
        </IonContent>
      </IonPage>
    );
  }

  function renderForm() {
    return (
      <>
  
        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-12">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={Icon}
                alt="Valyu"
              />
              
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                Sign up for ValyuLabel
              </h2>
              <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900">
                Enter your details
              </h2>
              
            </div>
            <form onSubmit={handleSubmit} className="mt-8 space-y-6" >
              <input type="hidden" name="remember" defaultValue="true" />

              <div className="-space-y-px  shadow-sm">
                <div>
                  <IonLabel position="fixed" className="sr-only">
                  Username
                  </IonLabel>
                  <IonInput
                    name="text"
                    type="text"
                    id="username"
                    value={fields.username}
                    required={true}
                    onIonChange={handleFieldChange}
                    className="relative block w-full appearance-none mt-2 rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="  Username"
                  />
                </div>

                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email Address
                  </label>
                  <IonInput
                    name="email"
                    type="email"
                    id="email"
                    value={fields.email}
                    required={true}
                    onIonChange={handleFieldChange}
                    className="relative block w-full appearance-none mt-2 rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="  Email Address"
                  />
                </div>

                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <IonInput
                    name="password"
                    type="password"
                    id="password"
                    required={true}
                    value={fields.password}
                    onIonChange={handleFieldChange}
                    className="relative block w-full appearance-none mt-2 rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="  Password"
                  />
                </div>
              
              <div>
                  <label htmlFor="password" className="sr-only">
                    Confirm Password
                  </label>
                  <IonInput
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    value={fields.confirmPassword}
                    required={true}
                    onIonChange={handleFieldChange}
                    className="relative block w-full appearance-none mt-2 rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="  Confirm Password"
                  />
                </div>
                </div>
  
              <div>
                <button
                  type="submit"
                  disabled={!validateForm()}
                  className="group relative flex w-full justify-center rounded-md mt-1.5 border border-transparent bg-teal-400 py-2 px-4 text-sm font-medium text-white hover:bg-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-300 focus:ring-offset-2">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  </span>
                    Sign up
                </button>
              </div>

            </form>

            <div className="flex">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
            </svg>
            <Link to="/login" className="pl-4">Back to Login</Link>
            </div>

          </div>
        </div>
      </>
    )
  
  }

  return newUser === null ? renderForm() : renderConfirmationForm();
}
