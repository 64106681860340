import {
  IonRouterOutlet,
  IonApp,
  IonPage,
} from "@ionic/react";
import { Route } from "react-router";
import LabelPage from "./LabelPage";
import LeaderboardPage from "./LeaderBoardPage";
import UserPage from "./UserPage";
import Navbar from "../components/Navbar";
import  "./pages.css";

export default function Home() {
  return (
    <IonApp>
      <IonPage>
      <IonRouterOutlet>
            <Route path="/label">
              <LabelPage />
            </Route>
            <Route path="/leaderboard">
              <LeaderboardPage />
            </Route>
            <Route path="/user">
              <UserPage />
            </Route>
      </IonRouterOutlet>
      <Navbar />
      </IonPage>
    </IonApp>
  );
}
