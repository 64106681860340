import React from 'react';
import "./UserPage.css";
import { 
  IonAccordion, 
  IonAccordionGroup,
  IonItem, 
  IonLabel
} from '@ionic/react';

const ProfileOptions: React.FC = () => {
  return (
    <IonAccordionGroup>
      <IonAccordion value="first">
        <IonItem slot="header" color="default" lines="none">
          <IonLabel>Profile</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Update email & password 
        </div>
      </IonAccordion>
      <IonAccordion value="second">
        <IonItem slot="header" color="default" lines="none">
          <IonLabel>Payment Methods</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          View connected wallet
        </div>
        <div className="ion-padding" slot="content">
          Update payment methods
        </div>
      </IonAccordion>
      <IonAccordion value="third">
        <IonItem slot="header" color="default" lines="none">
          <IonLabel>Theme</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Light
        </div>
        <div className="ion-padding" slot="content">
          Dark
        </div>
        <div className="ion-padding" slot="content">
          User's Setting
        </div>
      </IonAccordion>
      <IonAccordion value="fourth">
        <IonItem slot="header" color="default" lines="none">
          <IonLabel>Settings</IonLabel>
        </IonItem>
        <div className="ion-padding" slot="content">
          Terms and conditions
        </div>
      </IonAccordion>
    </IonAccordionGroup>
  );
}
export default ProfileOptions;