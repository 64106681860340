import {
  IonContent,
  IonHeader,
  IonPage,
  IonProgressBar,
  IonToolbar,
} from "@ionic/react";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import config from "../config";
import { Task } from "../models/task";
import "./pages.css";
import axios from "axios";
import LabelStudioUI from "../components/labelstudio/LabelStudioUI";
import AdComponent from "../components/ads/AdComponent";
import EarningsHeader from "../components/earnings/EarningsHeader";

const LabelPage: React.FC = () => {
  const [task, setTask] = useState<Task | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [numAnnotations, setNumAnnotations] = useState(0);

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      const session = await Auth.currentSession();

      try {
        const res = await axios.get(`${config.apiGateway.URL!}/next-task`, {
          headers: {
            Authorization: `${session.getIdToken().getJwtToken()}`,
          },
        });
        setTask(res.data);
      } catch (error) {
        setTask(null);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [numAnnotations]);

  async function handleSubmitAnnotation(annotation: any) {
    setTask(null);
    setIsLoading(true);
    const session = await Auth.currentSession();

    await axios.post(
      `${config.apiGateway.URL!}/annotation`,
      { result: annotation, taskId: task!.id },
      {
        headers: {
          Authorization: `${session.getIdToken().getJwtToken()}`,
        },
      }
    );

    setNumAnnotations((prev) => prev + 1);
  }

  return (
    <IonPage>
      <IonHeader collapse="condense">
        <IonToolbar>
          <EarningsHeader amount={123} symbol="£"></EarningsHeader>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonProgressBar
          hidden={!isLoading}
          type="indeterminate"
        ></IonProgressBar>
        <AdComponent
          mainText="Bring your friends to Valyu and get £10 each!"
          btnText="Get started"
          bgImage="https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=300&q=80"
        ></AdComponent>
        {!isLoading &&
          (task ? (
            <div className="ion-padding-top">
              <LabelStudioUI
                task={task}
                handleSubmitAnnotation={handleSubmitAnnotation}
              ></LabelStudioUI>
            </div>
          ) : (
            <div>No more tasks</div>
          ))}
      </IonContent>
    </IonPage>
  );
};

export default LabelPage;
