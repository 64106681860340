import React from "react";
import "./UserPage.css";
import Icon from '../../icon/valyu_png/5.png';
import Logout from "../../components/UserPage/Logout";

const UserPicName: React.FC = () => {
  return (
<div className="flex items-left space-x-4 pt-5">
    <img className="w-10 h-10 rounded-full" src={Icon} alt=""/>
    <div className="font-medium dark:text-white">
        <div>
            <h2>User</h2>
        </div> 
        <div className="items-start text-sm text-gray-500 dark:text-gray-400">
            Joined in January 2023
        </div>
        <Logout/>
    </div>
</div>  );
};

export default UserPicName;