import { IonCard, IonCardContent, IonLabel } from "@ionic/react";
import './AdComponent.css'


interface Props {
  mainText: string;
  btnText: string;
  bgImage: string;
}

const AdComponent: React.FC<Props> = ({ mainText, btnText, bgImage }) => {
  return (
    <div>
      <IonCard
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <IonCardContent className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
          <IonLabel className="ion-text-wrap text-black" >
            <b>{mainText}</b>
          </IonLabel>
          <div className="mt-3 flex items-center justify-center gap-x-6 lg:justify-start">
            <span className="rounded-md text-white bg-teal-500 px-3.5 py-1.5 text-base font-semibold leading-7   hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">{btnText}</span>
            </div>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default AdComponent;
