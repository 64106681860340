import React from "react";
import { IonList } from "@ionic/react";
import LeaderboardRow from "./LeaderboardRow";


interface LeaderboardData {
  name: string;
  score: number;
}

interface LeaderboardTableProps {
  data: LeaderboardData[];
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ data }) => {
  return (
    <IonList>
     {data.map((player, index) => (
        <LeaderboardRow key={index} name={player.name} score={player.score} />
      ))}
    </IonList>
  );
};

export default LeaderboardTable;
