import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./pages.css";
import LeaderboardTable from "../components/leaderboards/LeaderboardTable";
import LeaderboardHeader from "../components/leaderboards/LeaderboardHeader";

interface LeaderboardData {
  name: string;
  score: number;
}

const data: LeaderboardData[] = [
  { name: 'Player 1', score: 1000 },
  { name: 'Player 2', score: 900 },
  { name: 'Player 3', score: 800 },
];



const LeaderBoardPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Leaderboards</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} className="ion-padding">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Leaderboards</IonTitle>
          </IonToolbar>
        </IonHeader>
        <LeaderboardHeader nameLabel="Dataset 1" scoreLabel="Score" />
        <LeaderboardTable data={data} />

        <LeaderboardHeader nameLabel="Dataset 2" scoreLabel="Score" />
        <LeaderboardTable data={data} />

        <LeaderboardHeader nameLabel="Dataset 3" scoreLabel="Score" />
        <LeaderboardTable data={data} />

        <LeaderboardHeader nameLabel="Dataset 4" scoreLabel="Score" />
        <LeaderboardTable data={data} />

        <LeaderboardHeader nameLabel="Dataset 5" scoreLabel="Score" />
        <LeaderboardTable data={data} />

        <LeaderboardHeader nameLabel="Dataset 6" scoreLabel="Score" />
        <LeaderboardTable data={data} />
      </IonContent>
    </IonPage>
  );
};

export default LeaderBoardPage;
