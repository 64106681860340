import { Redirect, Route } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Home from "./pages/Home";
import Login from "./pages/LoginPage";
import Signup from "./pages/SignUpPage";
import { AppContext } from "./lib/contextLib";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import UnauthenticatedRoute from "./components/authentication/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/authentication/AuthenticatedRoute";

setupIonicReact();

const App: React.FC = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return isAuthenticating ? (
    <></>
  ) : (
    <IonApp>
      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <IonReactRouter>
          <Route exact path="/login">
            <UnauthenticatedRoute>
              <Login />
            </UnauthenticatedRoute>
          </Route>
          <Route exact path="/signup">
            <UnauthenticatedRoute>
              <Signup />
            </UnauthenticatedRoute>
          </Route>
          <Route exact path="/label">
            <AuthenticatedRoute>
              <Home />
            </AuthenticatedRoute>
          </Route>
          <Route exact path="/leaderboard">
            <AuthenticatedRoute>
              <Home />
            </AuthenticatedRoute>
          </Route>
          <Route exact path="/user">
            <AuthenticatedRoute>
              <Home />
            </AuthenticatedRoute>
          </Route>
          <Route exact path="/">
            <Redirect to="/label" />
          </Route>
        </IonReactRouter>
      </AppContext.Provider>
    </IonApp>
  );
};

export default App;
