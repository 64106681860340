import React from "react";
import "./UserPage.css";
import { useAppContext } from "../../lib/contextLib";
import { Auth } from "aws-amplify";


const Logout: React.FC = () => {
    const { userHasAuthenticated } = useAppContext();

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
  }
  return (
    <div className="text-left">
    <span className="inline-flex items-center rounded border mt-2 border-red-600 bg-red-600 px-1 py-1 text-white hover:bg-transparent focus:outline-none focus:ring active:text-red-500">
      <span className="text-sm font-medium" onClick={handleLogout}> Logout </span>
    </span>
  </div>
  );
};

export default Logout;