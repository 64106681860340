import LabelStudio from "label-studio";
import { Task } from "../models/task";

export function initializeLabelStudio(
  { url: image, id, labelConfig }: Task,
  handleSubmitAnnotation: any
) {
  const labelStudioConfig = {
    config: labelConfig,

    interfaces: ["controls"],

    task: {
      id,
      data: {
        image,
      },
    },

    onLabelStudioLoad: function (LS: any) {
      var c = LS.annotationStore.addAnnotation({
        userGenerate: true,
      });
      LS.annotationStore.selectAnnotation(c.id);
    },
    onSubmitAnnotation: async function (LS: any, annotation: any) {
      handleSubmitAnnotation(annotation.serializeAnnotation());
    },
  };

  new LabelStudio("label-studio", labelStudioConfig);
}
