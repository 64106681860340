import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import "../leaderboards/Leaderboard.css";

interface Props {
  amount: number;
  symbol: string;
}

const EarningsHeader: React.FC<Props> = ({ amount, symbol }) => {
  return (
    <div>
      <IonGrid class="ion-align-items-center">
        <IonRow class="ion-align-self-center">
          <IonCol>
            <IonLabel position="floating" className="text-2xl font-bold text-black">Wallet amount</IonLabel>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel position="floating" className="text-2xl font-bold text-black">{symbol} </IonLabel>
            <IonLabel position="floating" className="text-2xl font-bold text-black">{amount}</IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default EarningsHeader;
