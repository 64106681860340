
import AdComponent from "../components/ads/AdComponent";
import "./pages.css";
import UserHeader from "../components/UserPage/UserHeader";
import UserPicName from "../components/UserPage/UserPicName";
import ProfileOptions from "../components/UserPage/ProfileOptions";
import { IonApp, IonPage, IonContent } from "@ionic/react";

const UserPage: React.FC = () => {

  return (
  <IonApp>
    <IonPage>
      <IonContent fullscreen={true} className="ion-padding">
    <section className=" bg-white text-black">
      <div className="mx-auto max-w-screen-xl max-h-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg max-h-lg text-left ">
          <UserHeader/>
          <UserPicName/>
          <AdComponent
            mainText="Refer your friends and get £10!"
            btnText="Send invite link!"
            bgImage=""
          ></AdComponent>
          <ProfileOptions />
          </div>
        </div> 
        </section>
        </IonContent>
        </IonPage>
    </IonApp>
  );
};

export default UserPage;
