import { useContext, createContext, Dispatch } from "react";

interface State {
  isAuthenticated: boolean;
  userHasAuthenticated: Dispatch<boolean>;
}

export const AppContext = createContext<State>({} as State);

export function useAppContext() {
  return useContext(AppContext);
}
