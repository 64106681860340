import "label-studio/build/static/css/main.css";
import { PropsWithChildren, useEffect } from "react";
import { Task } from "../../models/task";
import { initializeLabelStudio } from "../../utilities/labelStudio";

interface LabelStudioUIProps {
  task: Task;
  handleSubmitAnnotation: any;
}

export default function LabelStudioUI({
  task,
  handleSubmitAnnotation,
}: PropsWithChildren<LabelStudioUIProps>) {
  useEffect(
    () => initializeLabelStudio(task, handleSubmitAnnotation),
    [task, handleSubmitAnnotation]
  );

  return <div id="label-studio"></div>;
}
