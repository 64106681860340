import React from "react";
import { IonRow, IonCol, IonLabel } from "@ionic/react";
import "./Leaderboard.css"

interface LeaderboardRowProps {
  name: string;
  score: number;
}

const LeaderboardRow: React.FC<LeaderboardRowProps> = ({ name, score }) => {
  return (
    <IonRow>
      <IonCol className="ion-text-center">
        <IonLabel className="font-medium">{name}</IonLabel>
      </IonCol>
      <IonCol className="ion-text-center">
        <IonLabel className="font-medium">{score}</IonLabel>
      </IonCol>
    </IonRow>
  );
};

export default LeaderboardRow;